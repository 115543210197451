.add-store-button {
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    max-width: 250px;
}

.add-store-button:hover {
  background-color: #f9f9f9;
}

.add-store-button:focus {
  outline: none;
}

.add-store-button:active {
  background-color: #f9f9f9;
}

.cards-container {
  display: flex;
  gap: 20px;
}