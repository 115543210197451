.table-container {
  width: 100%;
  color: #000;
  overflow: hidden;
  border-radius: 16px;
  background-color: transparent;

  .search {
    width: 30%;
    height: 90% !important;
  }

  button {
    padding: 0.5rem 1rem;
    background: #0f1729;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .button-text {
    display: flex;
    gap: 5px;
    height: 25px;
    line-height: 27px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 12px;
      text-align: left;
      font-size: 12px;
      background-color: #fff;
    }

    th {
      background-color: #fff;
      color: #000;
    }

    .product-image {
      width: 100px;
      height: 100px;
      max-height: 100px;
      max-width: 100px;
      height: auto;
      border-radius: 4px;
      object-fit: contain;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .description-container {
      margin-bottom: 10px;
      max-width: 28vw;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      overflow: scroll;

      .description-content {
        max-height: 60px;
        max-width: 25vw;
        transition: height 0.3s ease;
        line-height: 1.5;
        word-wrap: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 25px;
      }
    }
  }
}

.close-icon {
  width: 25px;
  position: absolute !important;
  top: 15px;
  right: 25px;
  color: #000;
}

.MuiTablePagination-actions {
  display: flex;
}

.filter-sort-container {
  background-color: #FFFFFF;
  box-shadow: 0px 32px 40px 0px #8896C81F;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px;
  height: 40px;
  border-radius: 16px;
}

.bulk-optimization {
  position: fixed;
  bottom: 20px;
  left: calc(250px + 5vh);
  background-color: #FFFFFF;
  padding: 18px 22px 18px 22px;
  border-radius: 10px;
  border: 1px solid #DFE4EB;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

}

.table-pagination {
    background-color: #FFFFFF;
    box-shadow: 0px 32px 40px 0px rgba(136, 150, 200, 0.1215686275);
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    gap: 20px;
    padding: 16px;
    height: 40px;
    border-radius: 16px;
}

.in-progress {
  filter: brightness(70%);
}

.MuiInputBase-root  {

  svg {
    rotate: 0deg !important;
    transform: rotate(0deg) !important;
  }

}

form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row !important;
  gap: 3px;
  align-items: start;
  justify-content: start;
  width: 100%;

  label {
    margin-bottom: 0.5rem;
  }

  input {
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
    background-color: #DFEBFD;
    height: 35px;
    width: 700px !important;
  }

}

.buy-now-button {
  margin-top: 10px;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  background: #0f1729;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  gap: 5px;
}

#status-select {
  display: flex;
  gap: 10px;
}
