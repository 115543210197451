.billing-section {
  display: flex;
  flex-direction: column;
  text-align: center;

  .billing-plans {
    margin-top: 4vh;
    margin-bottom: 10vh;
    display: flex;
    justify-content: space-evenly;
    gap: 2vw;

  }

  .popular-label {
    background: linear-gradient(180deg, #7854F7 0%, #0142FF 100%);
    color: #FFF;
    margin-top: -4vh;
    width: 99.5%;
    height: 6vh;
    border-radius: 40px 40px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    span {
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0;
      display: flex;
      gap: 10px;
      justify-content: center;
      text-align: center;
      align-items: baseline;
      margin-top: 15px;

    }

    img {
      height: 16px;
    }

  }

  .professional {
    background-image: url("../../assets/images/prof_bg.png") !important;

    .buy-button {
      background: #FFF !important;
      color: #44A9FD !important;
    }

    .buy-button:hover {
      background: #FFFFFF;
      color: #000 !important;
    }

    .plan-name {
      margin-top: -3vh !important;
    }
  }

  .billing-plan {
    min-width: 200px;
    min-height: 550px;
    max-width: 300px;
    max-height: 550px;
    background: linear-gradient(180deg, #9860FF 0%, #5F37EF 100%);
    height: 65vh;
    width: 20vw;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    color: #FFFFFF;

    .plan-name {
      font-size: 30px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0;
      text-align: left;
      margin-top: 6.6vh;
    }

    .plan-price {
      align-items: center;
      display: flex;
      gap: 10px;
      margin-bottom: -50px;

      .currency {
        font-size: 22px;
        font-weight: 300;
        line-height: 34px;
        letter-spacing: 5px;
        text-align: left;
      }

      .amount {
        font-size: 50px;
        font-weight: 300;
        line-height: 56px;
        letter-spacing: -0.13992606103420258px;
        text-align: left;
      }

      .original {
        text-decoration: line-through
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 10px;
      }
    }

    .plan-desc {
      display: flex;
      gap: 10px;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: left;
      align-items: center;

      img {
        width: 15px;
        height: 15px;
      }

    }

    .buy-button {
      width: 16vw;
      max-width: 260px;
      min-width: 160px;
      height: 55px;
      border-radius: 52px;
      border: 2.8px solid #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0;
      text-align: center;
      background: transparent;
      color: #fff;
    }

    .buy-button:hover {
      background: #FFFFFF;
      color: #44A9FD;
    }


  }

  .invoices-container {
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    justify-content: center;
    border-radius: 16px;
    box-shadow: 0px 32px 40px 0px #8896C81F;

    .invoice-container {
      background-color: #FFFFFF;
      margin-bottom: 30px;
      height: 40px;
      border-radius: 16px;
      width: 100%;
      border: 2px solid #DFE4EB;

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 20px;
      align-items: center;
      align-items: center; /* Vertical alignment */
      justify-items: center; /* Horizontal alignment */
    }

  }
}
