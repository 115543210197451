.container {
  padding: 16px;

  .head-container {
    background-color: #FFFFFF;
    box-shadow: 0px 32px 40px 0px #8896C81F;
    margin-bottom: 30px;
    padding: 16px;
    height: 40px;
    border-radius: 16px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0px;
    align-items: center;
    align-items: center; /* Vertical alignment */
    justify-items: center; /* Horizontal alignment */
  }


  .store-container {
    background-color: #FFFFFF;
    margin-bottom: 30px;
    height: 100px;
    border-radius: 16px;
    width: 100%;
    border: 2px solid #DFE4EB;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    align-items: center; /* Vertical alignment */
    justify-items: center; /* Horizontal alignment */

  }

  .stores-container {
    background-color: #FFFFFF;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    justify-content: center;
    border-radius: 16px;
    box-shadow: 0px 32px 40px 0px #8896C81F;

  }

  .store {
    background-color: #FFFFFF;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    text-align: start;

  }

  .platform-image {
    width: 75px;
    margin-right: 15px;
    margin-bottom: 5px;
    vertical-align: middle;
  }

  button {
    padding: 0.5rem 1rem;
    background: #0f1729;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .button-text {
    display: flex;
    gap: 5px;
    height: 25px;
    line-height: 27px;
  }

}

.modal-container {
  padding: 55px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #F8FAFF;
  width: 700px;
  max-width: 90%;
  outline: none;
  display: flex;
  border-radius: 40px;
  flex-direction: column;
  gap: 15px;
  max-height: 60vh;
  overflow: scroll;
  align-items: center;
  text-align: center;
  box-shadow: 0px 945px 265px 0px #00000000;

  .delete-button {
    padding: 17px 20px 17px 20px;
    border-radius: 30px;
    gap: 14px;
    background: red;
    color: #FFFFFF;
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

  }

  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 50%;
    margin-bottom: 10px;

    label {
      margin-bottom: 0.5rem;
    }

    input {
      padding: 0.5rem;
      border: none;
      border-radius: 4px;
      background-color: #DFEBFD;
      height: 35px;
      width: 100%;
    }
  }

  .store-options {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;

    .store-option {
      width: 80px;
      height: 80px;
      padding: 50px 43px 50px 43px;
      border-radius: 16px;
      gap: 10px;
      box-shadow: 0px 32px 40px 0px #8896C81F;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

    }
  }

  img {
    width: 100px;
  }
}
