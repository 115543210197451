body {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Spartan', cursive;
}

#root {
 height: 100vh;
  margin: 0;
  padding: 0;
}

div,button,span,strong,th,td{
    font-family:'Spartan', cursive !important;
}


.App {

  &-page {
    padding: 5vh;
    padding-left: calc(250px + 5vh);
    transition: padding-left 0.5s;
    background: #F8FAFF;

    &-content {
      margin-top: 30px;

      .page-title {
        font-size: 26px;
        margin-top: 30px;
      }

      .page-inner-content {
        margin-top: 50px;
      }

    }

  }

  .page {
    &-header {
      box-shadow: inset 0px -1px 0px #dbe1e7;
      margin: 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-buttons {
      display: flex;
      align-items: center;

      button,
      a {
        margin-left: 16px;
      }
    }

    &-credits-indicator {
      color: #75787e;
      font-size: 0.8rem;
      margin-right: 10px;

      &-error {
        color: #ff0000;
      }
    }

    &-title {
      margin: 0;
      padding: 32px 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0f0f0f;
    }
    &-container {
      padding: 40px 40px;
    }
  }
}

a {
  text-decoration: none;
}

.page-content {
    width: 100%;
}

button {
  height: 30px;
}