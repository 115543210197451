.login-page {
  display: flex;
  height: 100%;
  background-color: #F8FAFF;
}

.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .error-msg {
    color: red;
    font-size: 14px;
  }

  h4 {
    font-weight: 700;
    font-size: 40px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 320px;

    .form-group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      gap: 3px;

      label {
        margin-bottom: 0.5rem;
      }

      input {
        padding: 0.5rem;
        border: none;
        border-radius: 4px;
        background-color: #DFEBFD;
        height: 35px;
      }
    }

    button {
      color: #fff;
      border: none;
      cursor: pointer;
      height: 40px;
      background: linear-gradient(94.33deg, #00E0FF 4.77%, #015CFF 60.42%, #9860FF 85.2%);
      border-radius: 30px;
      width: 50%;
      align-self: center;
    }
  }

  .signup {
    margin-top: 25px;
    display: flex;
    gap: 5px;
  }
}

#or {
  position: relative;
  width: 320px;
  height: 50px;

  line-height: 50px;
  text-align: center;
}

#or::before,
#or::after {
  position: absolute;
  width: 130px;
  height: 1px;

  top: 24px;

  background-color: #aaa;

  content: '';
}

#or::before {
  left: 0;
}

#or::after {
  right: 0;
}