.auth-welcome {
  max-width: 800px;
  background: #0f1729;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 32px 25px;
  border-right: 1px solid black;

  &-wrapper {
    flex-direction: column;
    display: flex;
    flex: 1;
    justify-content: space-between;
    text-align: center;
    max-width: 520px;
    align-self: center;
    padding-top: 10vh;
  }

  &-title {
    font-size: 3.5rem;
    line-height: 1;
    margin: 0;
  }

  &-desc {
    font-size: 1.2rem;
    span{
      color: #BE6EF7;
      font-family: PT Serif;
      font-style: italic;
    }

  }

  &-company {
    padding-top: 40px;
  }
}

.auth-welcome-50-width {
  width: 55%;
}

.auth-welcome-40-width {
  width: 40%;
}

@media (max-width: 767px) {
  .auth-welcome {
    display: none;
  }
}