.jobs-container {

  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
  linear-gradient(0deg, #DFE4EB, #DFE4EB);
  border: 1px solid #DFE4EB;
  box-shadow: 0px 20px 60px 0px #80909B33;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .job {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
    linear-gradient(0deg, #DFE4EB, #DFE4EB);
    border: 1px solid #DFE4EB;
    padding: 16px;
    gap: 10px;
    border-radius: 6px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    width: 97%;
  }

}