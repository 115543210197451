.opt-modal-container {
  padding: 55px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #F8FAFF;
  width: 600px;
  min-height: 550px;
  max-width: 90%;
  outline: none;
  display: flex;
  border-radius: 40px;
  flex-direction: column;
  gap: 10px;
  max-height: 60vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden !important;

  .optimize-group {
    margin-bottom: 25px;
    width: 85%;

    .MuiButtonBase-root {
      height: 70px;
    }
  }

  .tone-group {
    margin-bottom: 25px;
    width: 85%;
  }

  .temperature-group {
    margin-bottom: 25px;
    width: 85%;
  }

  .MuiButtonBase-root {
    border-radius: 100px;
    text-transform: none;
    height: 40px;
  }

  .Mui-selected {
    background-color: #5F37EF !important;
    color: #FFFFFF !important;
  }

  h6 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
  }
}

.button-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.button-text {
  display: inline-block;
  vertical-align: middle;
}
