.dash-card {
  border-radius: 20px;
  position: relative;
  padding: 10px;
  color: #FFF;
  height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.2rem;
  }

  p {
    font-size: 2rem;
  }

  .dash-cta-button {
    background-color: #FFF;
    border-radius: 30px;
    border: none;
    position: absolute;
    right: 30px;
    bottom: 3.5vh;
    width: 45%;
    height: 5vh;
    font-size: 14px;
  }

  img {
    position: absolute;
    bottom: 2vh;
    left: 30px;
    height: 8vh;
    max-height: 70px;
    min-height: 25px;

  }

}

.v1 {
  background-color: #985fff;
  .dash-cta-button {
    color: #985fff;
  }
}

.v2 {
  background-color: #5052ff;
  .dash-cta-button {
    color: #5052ff;
  }
}

.v3 {
  background-color: #015cff;
  .dash-cta-button {
    color: #015cff;
  }
}

.v4 {
  background-color: #4042e3;
  .dash-cta-button {
    color: #4042e3;
  }
}