.sidebar {
  height: 100vh;
  width: 250px;
  background-color: #0f1729;
  box-shadow: 0 0 10px #0f1729;
  white-space: nowrap;
  transition: width 0.6s ease;
  position: fixed;
  z-index: 10;
  display: flex;
  text-align: center;

  *{
    text-decoration: none;
  }

  .separator {
    background: #363636;
    width: 100%;
    height: 1px;
    opacity: 0;
    transition: opacity 0.6s ease;
  }

  &-container {
    width: 248px;
    min-height: 60%;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: all 600ms ease;
    color: #75787e;
    padding: 15px 0 32px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox
    align-items: center;
    margin-top: 2vh;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }


  &-options {
    padding: 8px 16px;
    &-title {
      color: #0f1729;
      font-weight: 700;
      font-size: 0.8rem;
      text-transform: uppercase;
      padding: 0 12px 10px;
      transition: all 0.3s ease;
    }
    &.logout {
      margin-top: auto;
    }
  }

  &-option {
    height: 37px;
    width: 100%;
    color: #FFF;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 12px;
    transition: all 0.3s ease-in-out;
    margin-top: 5px;
    svg{
      width:20px;
      height: 20px;
      fill: #fff;
      stroke: #fff;
    }
    &.active,
    &:hover {
      background: #fff;
      color: #0f1729;

      svg > path {
        fill: #0f1729;
         stroke: #0f1729;
      }
    }

    > span {
      padding-left: 18px;
    }
  }
}

.sidebar-tabs-container{
  position: relative;
  padding-bottom: 50px;
  margin-top: 5vh;
}

.sidebar-tabs-container::-webkit-scrollbar {
  width: 4px;
  background: none;
  border-radius: 10px;
  padding-right: 2px;
}

.sidebar-tabs-container::-webkit-scrollbar-thumb {
  width: 4px;
  margin-right: 2px;
  background: #007CF8;
  -webkit-border-radius: 1ex;
  border-radius: 1ex;
}

.credits {
  border: none;
  border-radius: 15px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #000;
  background-color: #FFFFFF;
  width: 67%;
}

.help {
  color: #0f1729;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 5px;

  img {
    width: 85%;
  }
}

#help {
  position: absolute;
  bottom: 8vh;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
  text-align: center;
  justify-content: center;
}

#logout {
  position: absolute;
  bottom: 2vh;
  width: 70%;
  padding: 0.5rem 1rem;
  background-color: transparent;
  color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 5px;
}

.credit-widget {
  position: absolute;
  top: 2vh;
  right: 2.8vw;
  width: 280px;
  height: 120px;
  color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.credit-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  height: 25px;
  font-weight: 700;

  img {
    margin-bottom: 3px;
  }
}

.buy-button {
  height: 10px;
  padding: 15px 20px;
  font-size: 14px;
  background: linear-gradient(94.33deg, #00E0FF 4.77%, #015CFF 60.42%, #9860FF 85.2%);
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
